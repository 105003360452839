var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill mt-3"},[(_vm.recordFuelSegregation.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 grey lighten-4 pa-2",staticStyle:{"border-radius":"3px"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:" font font-size-sm  mt-2 text-uppercase font-weight-bold",staticStyle:{"border-radius":"3px"}},[_vm._v("Accounting For Fuel Sales And Revenue")]),_c('v-chip',{staticClass:"mt-1 mr-2",attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")((_vm.sum(_vm.recordedFuelSalesList, "totalAmountSold") + _vm.sum( _vm.pumps.reduce(function (acc, pump) { return acc.concat(pump); }, []), "totalAmountSold" ) + _vm.totalCreditCollection), _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")])])],1)]),_c('v-expansion-panel-content',[_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.recordFuelSegregation),function(revenueSale,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1 my-1 white px-2 py-3 ma-2 box-shadow-light"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('validationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:" font font-weight-medium font-size-md pa-1",attrs:{"label":"Mode Of Payment or Revenue Type","prepend-icon":"credit_card","items":_vm.modeOfPayments,"item-text":"name","item-value":"id","error-messages":errors[0],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(_vm.editing && _vm.editing.id === item.id)?_c('v-text-field',{staticClass:"font font-weight-medium font-size-sm",attrs:{"background-color":"transparent","flat":"","dense":"","solo":"","hide-details":"","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.name),callback:function ($$v) {_vm.$set(_vm.editing, "name", $$v)},expression:"editing.name"}}):_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('v-list-item-action',{staticStyle:{"padding":"0 !important","position":"absolute","top":"-9px","right":"6px"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"fab":"","icon":"","small":"","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v(_vm._s(_vm.editing && _vm.editing.id === item.id ? "edit" : "done_all"))])])],1)],1)]}}],null,true),model:{value:(revenueSale.mode),callback:function ($$v) {_vm.$set(revenueSale, "mode", $$v)},expression:"revenueSale.mode"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md pa-1",attrs:{"prepend-icon":"credit_card","label":"Total Amount","prefix":_vm.currency && _vm.currency.length > 0
                          ? _vm.currency[0].symbol
                          : 'GHS',"error-messages":errors[0],"dense":""},model:{value:(revenueSale.amount),callback:function ($$v) {_vm.$set(revenueSale, "amount", $$v)},expression:"revenueSale.amount"}})]}}],null,true)})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeRevenueRow(index)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" font font-weight-medium font-size-sm"},[_vm._v("Remove Item")])])],1)])}),0),(!_vm.isAccountsTallied)?_c('v-alert',{staticClass:"mx-2 mt-2",attrs:{"dense":"","type":"error"}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Account not tallied, kindly reaccess. Amount can be +/- 15 cedis ")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"font font-weight-medium mr-2 mt-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","small":"","disabled":_vm.sum(_vm.recordedFuelSalesList, 'totalAmountSold') +
                  _vm.sum(
                    _vm.pumps.reduce(function (acc, pump) { return acc.concat(pump); }, []),
                    'totalAmountSold'
                  ) +
                  _vm.totalCreditCollection <=
                  _vm.totalFuelSales},on:{"click":function($event){$event.stopPropagation();return _vm.addRevenueRow($event)}}},[_vm._v(" Add New Revenue ")])],1)],1)],1)],1)],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/payment-method.svg")}}),_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Add all revenue types for the fuel sales here "),_c('kbd',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.date))]),_vm._v(".")]),_c('span',{staticClass:"font font-size-sm font-weight-medium"},[_vm._v(" Revenue types may include sales collected via payment cards, mobile money or cash ")]),_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.sum(_vm.recordedFuelSalesList, 'totalAmountSold') +
          _vm.sum(
            _vm.pumps.reduce(function (acc, pump) { return acc.concat(pump); }, []),
            'totalAmountSold'
          ) +
          _vm.totalCreditCollection <=
          _vm.totalFuelSales,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addRevenueRow($event)}}},[_c('span',{staticClass:"font font-weight-medium font-size-sm",staticStyle:{"font-size":"12px !important"}},[_vm._v("Add Revenue types")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }