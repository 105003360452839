<template>
  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
    <div
      class="d-flex flex-column flex-grow-1 grey lighten-4 pa-2"
      style="border-radius: 3px;"
      v-if="recordFuelSegregation.length > 0"
    >
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><div
              class="d-flex flex-row flex-grow-1"
              style="justify-content: space-between;"
            >
              <span
                class=" font font-size-sm  mt-2 text-uppercase font-weight-bold"
                style="border-radius: 3px;"
                >Accounting For Fuel Sales And Revenue</span
              >

              <v-chip color="primary" small label class="mt-1 mr-2">
                <span class="font font-weight-medium font-size-md">
                  {{
                    (sum(recordedFuelSalesList, "totalAmountSold") +
                      sum(
                        pumps.reduce((acc, pump) => acc.concat(pump), []),
                        "totalAmountSold"
                      ) +
                      totalCreditCollection)
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}
                </span>
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-slide-y-transition group>
              <div
                v-for="(revenueSale, index) in recordFuelSegregation"
                :key="index"
                class="d-flex flex-column flex-grow-1 my-1 white px-2 py-3 ma-2 box-shadow-light"
              >
                <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
                  <div class="d-flex flex-row flex-grow-1">
                    <validationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        label="Mode Of Payment or Revenue Type"
                        prepend-icon="credit_card"
                        :items="modeOfPayments"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors[0]"
                        :loading="loading"
                        v-model="revenueSale.mode"
                        class=" font font-weight-medium font-size-md pa-1"
                      >
                        <template v-slot:item="{ index, item }">
                          <div class="d-flex flex-column flex-grow-1">
                            <v-text-field
                              background-color="transparent"
                              flat
                              v-if="editing && editing.id === item.id"
                              dense
                              class="font font-weight-medium font-size-sm"
                              v-model="editing.name"
                              solo
                              hide-details
                              autofocus
                              @keyup.enter="edit(index, item)"
                            >
                            </v-text-field>
                            <span
                              v-else
                              class="font font-weight-medium font-size-sm"
                              >{{ item.name }}</span
                            >
                            <v-spacer />
                            <v-list-item-action
                              @click.stop
                              style="padding: 0 !important;position: absolute;top:-9px; right: 6px;"
                            >
                              <v-btn
                                fab
                                icon
                                small
                                text
                                color="primary"
                                @click.stop.prevent="edit(index, item)"
                              >
                                <i
                                  class="material-icons-outlined font-size-sm"
                                  >{{
                                    editing && editing.id === item.id
                                      ? "edit"
                                      : "done_all"
                                  }}</i
                                >
                              </v-btn>
                            </v-list-item-action>
                          </div>
                        </template>
                      </v-autocomplete>
                    </validationProvider>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        prepend-icon="credit_card"
                        label="Total Amount"
                        v-model="revenueSale.amount"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        :error-messages="errors[0]"
                        class="font font-weight-medium font-size-md pa-1"
                        dense
                      />
                    </ValidationProvider>
                  </div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                        color="red"
                        @click.stop="removeRevenueRow(index)"
                      >
                        <i class="material-icons-outlined">delete</i>
                      </v-btn>
                    </template>
                    <span class=" font font-weight-medium font-size-sm"
                      >Remove Item</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </v-slide-y-transition>
            <v-alert
              v-if="!isAccountsTallied"
              dense
              type="error"
              class="mx-2 mt-2"
            >
              <span class="font font-weight-medium font-size-sm"
                >Account not tallied, kindly reaccess. Amount can be +/- 15
                cedis
              </span>
            </v-alert>

            <div
              class="d-flex flex-row flex-grow-1 "
              style="justify-content: flex-end;"
            >
              <v-btn
                color="primary"
                style="font-size: 12px;"
                small
                :disabled="
                  sum(recordedFuelSalesList, 'totalAmountSold') +
                    sum(
                      pumps.reduce((acc, pump) => acc.concat(pump), []),
                      'totalAmountSold'
                    ) +
                    totalCreditCollection <=
                    totalFuelSales
                "
                @click.stop="addRevenueRow"
                class="font font-weight-medium mr-2 mt-2"
              >
                Add New Revenue
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/payment-method.svg" style="width: 80px;" />
      <span class="font font-weight-medium"
        >Add all revenue types for the fuel sales here
        <kbd class="mr-2">{{ date }}</kbd
        >.</span
      >
      <span class="font font-size-sm font-weight-medium">
        Revenue types may include sales collected via payment cards, mobile
        money or cash
      </span>
      <v-btn
        :disabled="
          sum(recordedFuelSalesList, 'totalAmountSold') +
            sum(
              pumps.reduce((acc, pump) => acc.concat(pump), []),
              'totalAmountSold'
            ) +
            totalCreditCollection <=
            totalFuelSales
        "
        @click.stop="addRevenueRow"
        small
        color="primary"
        class="mt-3"
      >
        <span
          class="font font-weight-medium font-size-sm"
          style="font-size: 12px !important;"
          >Add Revenue types</span
        >
      </v-btn>
    </div>
  </div>
</template>
<script>
import { currencyFormat } from "../../../filters/currencyFormat";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Input field is required"
});

export default {
  name: "RecordedLubes",
  components: {
    ValidationProvider
  },
  props: {
    modeOfPayments: {
      type: Array,
      default: () => []
    },
    currency: { type: Array, default: () => [] },
    date: {
      type: String,
      default: null
    },
    recordedFuelSalesList: {
      type: Array,
      default: () => []
    },
    pumps: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    recordFuelSegregation: {
      type: Array,
      default: () => []
    },
    totalFuelSales: {
      type: Number,
      default: 0
    },
    totalCreditCollection: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    editing: null,
    model: null,
    isAccountsTallied: true,
    totalFuelRecordings: 0
  }),
  watch: {
    totalFuelSales(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSalesList, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        this.totalCreditCollection;
      const balance = this.totalFuelRecordings - value;

      this.isAccountsTallied = balance >= -15;
    },
    totalCreditCollection(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSalesList, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        value;
      this.isAccountsTallied =
        this.totalFuelRecordings - this.totalFuelSales >= -15;
      console.log(this.isAccountsTallied);
    }
  },
  methods: {
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = { ...item };
      } else {
        this.$store.dispatch("prices/updateModeOfPayment", {
          ...this.editing
        });
        this.editing = null;
      }
    },
    addNewRevenueType() {
      this.$emit("openRevenueDialog", "generate");
    },
    addRevenueRow() {
      this.$emit("addRevenueRow", { mode: "", amount: 0 });
    },
    removeRevenueRow(index) {
      this.recordFuelSegregation.splice(index, 1);
    }
  },
  filters: {
    currencyFormat
  }
};
</script>
